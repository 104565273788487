// React
import { useState } from 'react'

// Next
import { useRouter } from 'next/router'

// Components
import { AnimatedInput } from '@/components/blocks/animated-input'

// Icons
import { SearchIcon } from 'lucide-react'
import { useTranslation } from 'react-i18next'

const SimpleSearch: React.FC = () => {
  const router = useRouter()
  const [inputValue, setInputValue] = useState('')
  const { t } = useTranslation('components/image-search')

  const submitSearchForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    router.push(`/search?query=${inputValue}`)
  }

  return (
    <form onSubmit={submitSearchForm}>
      <div className='flex flex-col items-start space-x-0 space-y-2 md:flex-row md:space-x-2 md:space-y-0'>
        <div className='w-full'>
          <AnimatedInput
            id='meme-search'
            name='meme-search'
            label={t('searchBar.input.label')}
            value={inputValue}
            placeholders={
              t('searchBar.input.placeholders', {
                returnObjects: true,
              }) as string[]
            }
            required
            buttonLabel={t('searchBar.input.button')}
            buttonIcon={
              <SearchIcon className='h-5 w-5 text-white' aria-hidden='true' />
            }
            buttonType='submit'
            loading={false}
            onChange={(value) => setInputValue(value)}
          />
        </div>
      </div>
    </form>
  )
}

export { SimpleSearch }
