import React from 'react'
import { Brush, Building2, GlobeIcon, Megaphone } from 'lucide-react'
import { useTranslation } from 'next-i18next'

const icons = {
  GlobeIcon,
  Brush,
  Building2,
  Megaphone,
}

const WhoNeedsIt: React.FC = () => {
  const { t } = useTranslation('components/who-needs-it')
  const features = t('features', { returnObjects: true }) as {
    name: string
    description: string
    icon: keyof typeof icons
  }[]

  return (
    <section className='bg-white py-24 sm:py-32'>
      <div className='mx-auto max-w-7xl px-6 lg:px-8'>
        <div className='mx-auto max-w-2xl sm:text-center'>
          <h2 className='text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl'>
            {t('title')}
          </h2>
          <p className='mt-6 text-lg leading-8 text-gray-600'>
            {t('description')}
          </p>
        </div>
        <div className='mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none'>
          <dl className='grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-4'>
            {features.map((item) => {
              const Icon = icons[item.icon]
              return (
                <div key={item.name} className='flex flex-col'>
                  <dt className='text-base font-semibold leading-7 text-gray-900'>
                    <div className='mb-6 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600'>
                      <div className='h-6 w-6 text-white' aria-hidden='true'>
                        <Icon />
                      </div>
                    </div>
                    {item.name}
                  </dt>
                  <dd className='mt-1 flex flex-auto flex-col text-base leading-7 text-gray-600'>
                    <p className='flex-auto'>{item.description}</p>
                  </dd>
                </div>
              )
            })}
          </dl>
        </div>
      </div>
    </section>
  )
}

export default WhoNeedsIt
