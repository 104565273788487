// Next
import { NextPage } from 'next'
import { AppProps } from 'next/app'
import dynamic from 'next/dynamic'
import { appWithTranslation } from 'next-i18next'

// Components
import ErrorBoundary from '@/components/errors/error-boundary'
import GoogleAnalytics from '@/components/scripts/google-analytics'
import Posthog from '@/components/scripts/posthog'

const BaseLayout = dynamic(() =>
  import('@/components/layouts/base-layout').then((mod) => mod.BaseLayout)
)
const LandingLayout = dynamic(() =>
  import('@/components/layouts/landing-layout').then((mod) => mod.LandingLayout)
)

// Utils
import { useLayout } from '@/utils/ui/landing/layout'
import { UserContextProvider } from '@/utils/auth/user-context'

// Styles
import '@/styles/global.css'
import '@radix-ui/themes/styles.css'

// External
import { Toaster } from 'react-hot-toast'
import { Theme } from '@radix-ui/themes'

const layouts = {
  main: BaseLayout,
  landing: LandingLayout,
}

const App: NextPage<AppProps> = ({ Component, pageProps }: AppProps) => {
  const layoutType = useLayout()
  const Layout = layouts[layoutType]

  return (
    <Theme
      appearance='light'
      accentColor='violet'
      grayColor='sand'
      scaling='95%'
    >
      <UserContextProvider>
        <ErrorBoundary>
          <Layout>
            <Posthog />
            <GoogleAnalytics />
            <Component {...pageProps} />
            <Toaster
              position='bottom-center'
              reverseOrder={false}
              toastOptions={{
                duration: 5000,
              }}
            />
          </Layout>
        </ErrorBoundary>
      </UserContextProvider>
    </Theme>
  )
}

export default appWithTranslation(App)
