import { useTranslation } from 'next-i18next'
import {
  BadgeCheckIcon,
  BotIcon,
  FingerprintIcon,
  ImageIcon,
  InstagramIcon,
  LanguagesIcon,
  LockIcon,
  SearchIcon,
  SparklesIcon,
  SquareCodeIcon,
} from 'lucide-react'

const icons = {
  ImageIcon,
  SparklesIcon,
  InstagramIcon,
  FingerprintIcon,
  SquareCodeIcon,
  LanguagesIcon,
  BotIcon,
  LockIcon,
  BadgeCheckIcon,
  SearchIcon,
}

export default function WhySupermeme({
  title,
  subtitle,
  additionalText,
  features,
}: {
  title?: string
  subtitle?: string
  additionalText?: string
  features?: {
    title: string
    description: string
    icon: keyof typeof icons
  }[]
}) {
  const { t } = useTranslation('components/why-supermeme')
  const defaultFeatures = t('features', { returnObjects: true }) as {
    title: string
    description: string
    icon: keyof typeof icons
  }[]

  return (
    <div className='bg-white py-12 sm:py-16'>
      <div className='mx-auto max-w-7xl px-6 lg:px-8'>
        <div className='mx-auto max-w-2xl lg:text-center'>
          <h2 className='text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl'>
            {title || t('title')}
          </h2>
          <p className='mt-6 text-lg leading-8 text-gray-600'>
            {subtitle || t('subtitle')}
          </p>
        </div>
        <div className='mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none'>
          <dl className='grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3'>
            {(features || defaultFeatures).map((feature) => {
              const Icon = icons[feature.icon]
              return (
                <div key={feature.title} className='flex flex-col'>
                  <dt className='flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900'>
                    <Icon
                      className='h-5 w-5 flex-none text-indigo-600'
                      aria-hidden='true'
                    />
                    {feature.title}
                  </dt>
                  <dd className='mt-4 flex flex-auto flex-col text-base leading-7 text-gray-600'>
                    <p className='flex-auto'>{feature.description}</p>
                  </dd>
                </div>
              )
            })}
          </dl>
        </div>
        <p className='mt-16 text-center text-lg font-semibold leading-8 text-gray-600'>
          {additionalText || t('additionalText')}
        </p>
      </div>
    </div>
  )
}
