import { ArrowRightIcon } from 'lucide-react'
import { Button } from '@/components/ui/button'
import Link from 'next/link'
import { useTranslation } from 'next-i18next'

export default function WhyYouNeedIt() {
  const { t } = useTranslation('components/why-you-need-it')
  const reasonsTranslated = t('reasons', { returnObjects: true }) as {
    title: string
    description: string
  }[]

  return (
    <div className='bg-white py-12 sm:py-16'>
      <div className='mx-auto max-w-5xl px-6 lg:px-8'>
        <h2 className='mb-12 text-center text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl'>
          {t('title')}
        </h2>
        <div className='grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3'>
          {reasonsTranslated.map((reason, index) => (
            <div
              key={index}
              className={`rounded-lg bg-indigo-600 p-6 ${index === 0 ? 'sm:col-span-2 lg:col-span-1' : ''}`}
            >
              <h3 className='mb-2 text-xl font-semibold text-white'>
                {reason.title}
              </h3>
              <p className='text-indigo-100'>{reason.description}</p>
            </div>
          ))}
        </div>
        <div className='mt-12 text-center'>
          <h3 className='mb-2 text-2xl font-semibold text-gray-900'>
            {t('cta.title')}
          </h3>
          <p className='mb-6 text-gray-600'>{t('cta.description')}</p>

          <Button asChild>
            <Link href='/meme-marketing'>
              {t('cta.button')}
              <ArrowRightIcon className='ml-2 h-5 w-5' />
            </Link>
          </Button>
        </div>
      </div>
    </div>
  )
}
