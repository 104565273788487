// React
import React from 'react'

// Next
import Link from 'next/link'

// Components
import Logo from '@/components/blocks/logo'

// External
import { useTranslation } from 'next-i18next'
import { ExternalLinkIcon } from 'lucide-react'

const Footer: React.FC = () => {
  const { t } = useTranslation(['common'])

  const menuSections = [
    {
      title: t('layouts.footer.nav.product.title'),
      items: [
        {
          label: t('layouts.footer.nav.product.items.textToMeme'),
          link: '/text-to-meme',
        },
        {
          label: t('layouts.footer.nav.product.items.templateToMeme'),
          link: '/meme-generator',
        },
        {
          label: t('layouts.footer.nav.product.items.searchTemplates'),
          link: '/search',
        },
        {
          label: t('layouts.footer.nav.product.items.memeMaker'),
          link: '/meme-maker',
        },
        {
          label: t('layouts.footer.nav.product.items.pricing'),
          link: '/pricing',
        },
      ],
    },
    {
      title: t('layouts.footer.nav.resources.title'),
      items: [
        {
          label: t('layouts.footer.nav.resources.items.pitchDeck'),
          link: '/pitch-deck',
        },
        {
          label: t('layouts.footer.nav.resources.items.privacyPolicy'),
          link: '/privacy-policy',
        },
        {
          label: t('layouts.footer.nav.resources.items.termsAndConditions'),
          link: '/terms-and-conditions',
        },
      ],
    },
    {
      title: t('layouts.footer.nav.useCases.title'),
      items: [
        {
          label: t('layouts.footer.nav.useCases.items.forMarketers'),
          link: '/meme-marketing',
        },
        {
          label: t('layouts.footer.nav.useCases.items.forEducators'),
          link: '/teaching-with-memes',
        },
        {
          label: t('layouts.footer.nav.useCases.items.forInfluencers'),
          link: '/influencer-memes',
        },
      ],
    },
    {
      title: t('layouts.footer.nav.company.title'),
      items: [
        {
          label: t('layouts.footer.nav.company.items.giveFeedback'),
          link: 'https://supermeme.canny.io/what-should-we-build',
          openInNewTab: true,
        },
        {
          label: t('layouts.footer.nav.company.items.contact'),
          link: '/contact',
        },
        {
          label: t('layouts.footer.nav.company.items.team'),
          link: '/team',
        },
      ],
    },
  ]

  return (
    <footer
      className='bg-gray-50 text-gray-700'
      aria-labelledby='footer-heading'
    >
      <h2 id='footer-heading' className='sr-only'>
        {t('layouts.footer.sr.heading')}
      </h2>
      <div className='mx-auto max-w-full px-6 pb-8 pt-16 sm:pt-24 lg:px-8 lg:pt-32'>
        <div className='xl:grid xl:grid-cols-3 xl:gap-8'>
          <div className='space-y-4'>
            <Logo />
            <p className='text-sm leading-6 text-gray-600'>
              {t('layouts.footer.tagline')}{' '}
            </p>
            {/* Hack to force load Anton font */}
            <span style={{ fontFamily: 'Anton', opacity: 0.1 }}>.</span>
            <div>
              <a
                href='https://www.producthunt.com/posts/supermeme-ai?utm_source=badge-top-post-badge&amp;utm_medium=badge&amp;utm_souce=badge-supermeme-ai'
                target='_blank'
              >
                <img
                  src='https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=345517&amp;theme=dark&amp;period=daily'
                  alt='Supermeme.ai - AI powered meme generator | Product Hunt'
                  style={{ width: 250, height: 54 }}
                  width='250'
                  height='54'
                />
              </a>
            </div>
          </div>
          <div className='mt-8 grid grid-cols-1 gap-8 md:mt-16 md:grid-cols-3 xl:col-span-2 xl:mt-0'>
            {menuSections.map((m) => (
              <div key={`key_footer_section_${m.title}`} className='space-y-4'>
                <h3 className='font-bold'>{m.title}</h3>
                <div className='space-y-4'>
                  {m.items.map((item) => (
                    <Link
                      key={`key_footer_label_${item.label}`}
                      href={item.link}
                      legacyBehavior
                    >
                      <a
                        className='flex items-center justify-start'
                        target={item.openInNewTab ? '_blank' : '_self'}
                      >
                        {item.label}
                        {item.openInNewTab && (
                          <ExternalLinkIcon className='ml-1 h-3 w-3' />
                        )}
                      </a>
                    </Link>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className='mt-16 border-t border-gray-900/10 pt-8 sm:mt-20 lg:mt-24'>
          <p className='text-xs leading-5 text-gray-500'>
            &copy; {new Date().getFullYear()} Supermeme.ai. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  )
}

export { Footer }
