import Link from 'next/link'
import React from 'react'
import { useTranslation } from 'next-i18next'

interface CTAProps {
  mainHeading?: string
  subHeading?: string
}

const CTAComponent: React.FC<CTAProps> = ({ mainHeading, subHeading }) => {
  const { t } = useTranslation('components/cta')

  return (
    <div className='mx-auto max-w-4xl rounded-lg bg-indigo-700'>
      <div className='px-6 py-12 sm:px-6 sm:py-16 lg:px-8'>
        <div className='mx-auto max-w-2xl text-center'>
          <h2 className='text-3xl font-bold tracking-tight text-white sm:text-4xl'>
            {mainHeading || t('mainHeading')}
          </h2>
          <p className='mx-auto mt-6 max-w-xl text-lg leading-8 text-indigo-200'>
            {subHeading || t('subHeading')}
          </p>
          <div className='mt-10 flex items-center justify-center gap-x-6'>
            <Link
              href='/login'
              className='w-full max-w-sm rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white'
            >
              {t('button')}
            </Link>
          </div>
        </div>
        {/* TODO: add back when app issues are sorted */}
        {/* <img
          src='/google-play-badge.png'
          alt={t('badges.googlePlay')}
          className='h-8'
        />
        <img
          src='/app-store-badge.png'
          alt={t('badges.appStore')}
          className='h-8'
          /> */}
      </div>
    </div>
  )
}

export default CTAComponent
